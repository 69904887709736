//!!!Build Form!!!//
//Setting Extra-Class for checkbox-input
document.querySelectorAll('textarea').forEach(item => {
    item.closest('.clearfix').classList.add('w100')
});

//Setting Extra-Class for checkbox-input
document.querySelectorAll('input[type*="checkbox"]').forEach(item => {
    item.closest('.clearfix').classList.add('checkbox-input')
});

// Change Button-Text
document.querySelectorAll('.actions li.submit button').forEach(item => {
    item.innerText = 'Formular absenden'
    item.setAttribute("role", "button")
    item.setAttribute("aria-label", "Formular senden")
});


// Build Select-Dropdown
document.querySelectorAll('form select').forEach(item => {
    const select = item
    const selectStartLabel = select.options[select.selectedIndex].innerHTML
    const options = select.querySelectorAll('option'); //options can contain more than one element

    //Taking all options into a string
    let optionContent = ''
    options.forEach(item => {
        if (item.value !== '') {
            optionContent += `<div class="selectOption" data-value="${item.value}">${item.innerText}</div>`
        }
    });

    select.parentElement.innerHTML += `
        <div class="selectContainer">
            <div class="currentOption">
                ${selectStartLabel}
            </div>
            <div class="selectContainerInner">${optionContent}</div>
        </div>
    `
});

//!!!Control Form!!!//
// Toggle SelectContainer
const selectContainers = document.querySelectorAll('.selectContainer')
selectContainers.forEach(item => {
    item.addEventListener('click', event => {

        const currentContainer = event.target

        // remove active class for all select containers, but not for the current clicked select container
        removeActive(selectContainers, currentContainer)

        // set active class for clicked select container
        item.classList.toggle('active')

        if(item.classList.contains('active')) {
            const selectOptions = item.querySelectorAll('.selectOption')
            const currentOption = item.querySelector('.currentOption')
            const select = item.closest('.input').querySelector('select')

            selectOptions.forEach(item => {
                item.addEventListener('click', event => {
                    const option = event.target
                    const label = option.textContent
                    const value = option.dataset.value

                    currentOption.textContent = label
                    select.value = value

                    checkPartnerInputValue()
                })
            })
        }

    })
})

const removeActive = (selects, currentSelect) => {
    selects.forEach(item => {
        if (item !== currentSelect.closest('.selectContainer')) {
            item.classList.remove('active')
        }
    })
}
